import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
import companyLogo from "./../../assets/wmelogo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Navbar = ({ scrollCarousel }) => {
  const [isCollapsed, setIsCollpased] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  function collapse() {
    setIsCollpased(true);
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {}, [isCollapsed]);
  return (
    <nav className="navbar">
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <img src={companyLogo} alt="WarrantMe" className="logo" />
        <h3 className="logotext">WarrantyMe</h3>
      </div>
      <nav className={`nav-links ${isScrolled ? "sticky" : ""}`}>
        <NavLink
          to="/"
          className={(state) => (state.isActive ? "activeNavLink" : "home")}
        >
          <li>Home</li>
        </NavLink>
        <NavLink to="/" className={"home"}>
          <div
            onClick={() => {
              scrollCarousel();
            }}
          >
            <li>Why WarrantyMe</li>
          </div>
        </NavLink>
        <NavLink
          to="/aboutus"
          className={(state) => (state.isActive ? "activeNavLink" : "aboutus")}
        >
          <li>About Us</li>
        </NavLink>
        <NavLink
          to="/advisors"
          className={(state) => (state.isActive ? "activeNavLink" : "advisors")}
        >
          <li>Our Advisors</li>
        </NavLink>
        <NavLink
          to="/business"
          className={(state) => (state.isActive ? "activeNavLink" : "business")}
        >
          <li>For Business</li>
        </NavLink>
        {/* <NavLink to="/contact-us" className={(state) => state.isActive ? "activeNavLink" : "business"}>
          <li>Contact Us</li>
        </NavLink> */}
      </nav>
      <div className="respToggle">
        {isCollapsed ? (
          <MenuIcon
            style={{ color: "white", marginRight: "20px", fontSize: "36px" }}
            onClick={() => {
              setIsCollpased(false);
            }}
          />
        ) : (
          <CloseIcon
            style={{ color: "white", marginRight: "20px", fontSize: "36px" }}
            onClick={() => {
              setIsCollpased(true);
            }}
          />
        )}
      </div>
      {isCollapsed ? null : (
        <div
          className="respOffcanvas"
          style={{
            backgroundColor: "rgba(22, 25, 30, 0.8)",
            backdropFilter: "blur(2px)",
            zIndex: "2",
            width: "100vw",
          }}
        >
          <Link
            to="/"
            className="home"
            onClick={() => {
              collapse();
            }}
          >
            <p>Home</p>
          </Link>
          <Link
            to="/"
            className="home"
            onClick={() => {
              collapse();
            }}
          >
            <div
              onClick={() => {
                scrollCarousel();
              }}
            >
              <p>Why WarrantyMe</p>
            </div>
          </Link>

          <Link
            to="/aboutus"
            className="aboutus"
            onClick={() => {
              collapse();
            }}
          >
            <p>About Us</p>
          </Link>
          <Link
            to="/advisors"
            className="advisors"
            onClick={() => {
              collapse();
            }}
          >
            <p>Our Advisors</p>
          </Link>
          <Link
            to="/business"
            className="business"
            onClick={() => {
              collapse();
            }}
          >
            <p>For Business</p>
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
