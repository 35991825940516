import React from "react";
import "./Terms.css";
import Navbar from "../../components/Navbar/Navbar.jsx";
import BottomBar from "./../../components/BottomBar/BottomBar";
export default function Terms() {
  return (
    <div>
      <Navbar />
      <div className="privCont" style={{ color: "white", fontSize: "18px" }}>
        <strong
          style={{
            marginTop: "60px",
            marginBottom: "30px",
            fontSize: "32px",
            width: "100%",
          }}
        >
          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "60px !important",
            }}
          >
            {" "}
            Terms and Conditions
          </p>
        </strong>
        <p className="mt-10 terms">
          The WarrantyMe Application use and transfer of information received
          from Google APIs to any other app will adhere to{" "}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy"
            target="_blank"
          >
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements, The WarrantyMe Application
          is owned and operated by Dragua RSM Technologies Pvt Ltd Your access
          to and use of all information and functions on this Application
          including the services are provided subject to the following terms and
          conditions. <br />
          This application is a compliment to the new system of saving digital
          invoices/bills of customers Electronic products purchased, this app is
          customized for the use of retailers as well as the customers.
          <br /> We reserve the right to amend this Notice at any time and your
          use of the Application following any amendments will represent your
          agreement to be bound by these terms and conditions as amended. We,
          therefore, recommend that each time you access our Application you
          read these terms and conditions. <br />
          <p className="orangeFont">Article 1 - DEFINITIONS</p> <br />
          The parties which have been referred to in this agreement are: <br />
          a) “WarrantyMe”, Us, And We: “Dragua RSM Technologies Pvt Ltd”, as the
          creator, operator, and publisher of this Application, operate and owns
          this Application, through its registered users providing the services
          which are available to the retailer and Customers as a user.
          WarrantyMe/Dragua RSM Technologies Pvt Ltd Us, We, Our, Ours and other
          first-person pronouns will refer to the WarrantyMe/Dragua RSM
          Technologies Pvt Ltd, as well as all the staff of “WarrantyMe/Dragua
          RSM Technologies Pvt Ltd”. <br />
          b) You, being the Retailer or Customer as a User and the Client will
          be referred as You, Your, yours, or as User or Client in this
          agreement
          <br /> c) The parties to this Agreement (“WarrantyMe/Dragua RSM
          Technologies Pvt Ltd” and “You”) will be referred to as Parties.
          <br />
          <p className="orangeFont">Article 2 - ACCEPTANCE AND DISCLAIMER</p>
          <br /> By using the WarrantyMe Application you warrant that you have
          read and reviewed this Agreement and that you are bound by its terms
          and conditions. If you do not agree to be bound by this Agreement,
          please hold the use of our Application. “WarrantyMe” only provides use
          of this Application functions and Services to you if you assent to
          this Agreement.
          <br /> <p className="orangeFont">Article 3 - LICENCE</p>
          <br /> a) When you use our Application, we give you a limited licence
          to access and use the functions of the app for personal use. You are
          permitted to use this Application for your personal use only provided
          that you should abide by all the terms and conditions and also do not
          try to delete or change any copyright symbol, trademark or other
          proprietary notice and its functions. Your use of our Application in
          any other way infringes our intellectual property rights.
          <br /> b) you are not permitted to copy, reproduce, republish,
          distribute or display the Application without our prior written
          permission. The licence to access and use the functions and
          information on our Application does not include the right to use any
          data mining robots or other extraction tools. The licence also does
          not permit you to Meta tag or mirror our Application without our prior
          written permission. We reserve the right to serve you with notice if
          we become aware of mirroring of our Application. <br />
          <p className="orangeFont">Article 4 - FEES</p> <br />
          For every product registered into the application - we charge the
          retailer, exact charge will be written in the agreement. (This charge
          is to manage the customers and provide them service through the app)
          We also raise invoice to the retailer - To collect the charge. <br />
          <p className="orangeFont"> Article 5 - RETAILER APPLICATION</p>
          <br /> In the retailer application the app is provided to the retailer
          where they can collect the customers data like Name, Phone number,
          Email, Pin code, address, and birth date, it uses customers location,
          and access to camera to upload bills this data is reflected in the
          customers app which makes it easier for the executives to takes the
          details of the customer. <br />
          <p className="orangeFont">Article 6 - RETAILER APP FUNCTIONS</p>{" "}
          <br />
          The retailers are provided with a section to upload their business
          offers, the sales report are displayed using the graphs. Through the
          app loyalty points can also be captured, using the customer’s mobile
          number. Coupons are generated in the app for the customers
          <br /> <p className="orangeFont">Article 7 - CUSTOMER APPLICATION </p>
          <br />
          The customer’s products are managed through the app from which the
          invoice are uploaded and can be accessed through the app, the
          locations of service centres are provided to track the product
          health.The FAQ’s, Contact details of the brand are displayed in the
          app. The brand product rating is provided and it features a direct
          warranty claim support and assistance. There is also a chat support,
          guide for warranty claim which explains the customer how to claim
          warranty and Checkpoints for warranty The customer request is forward
          to the consent brand our app only forward the request and are not
          responsible for the brand not responding or providing service as the
          company is a mediator. The customer can also save their data in the
          app for their personal record <br />
          <p className="orangeFont">Article 8 - INFORMATION YOU PROVIDE</p>{" "}
          <br />
          a) You are responsible for all and any Content you share on our
          Application. When you provide Content you retain ownership of the
          intellectual property in that information however you grant us a
          worldwide, non-exclusive, royalty-free licence (with the right to
          sublicense) to use (including copy, reproduce, adapt, modify, publish,
          broadcast, transmit, display and distribute) the Content in any and
          all media including forms not yet developed.
          <br /> b) By sharing information on this Application, you provide us
          with an undertaking that such Content does not infringe the rights of
          someone else and that it does not violate the law in any other way
          such as by being defamatory, being of racist content or is
          threatening. You agree to indemnify and hold us harmless from any
          liability, claim, action, demand, loss, costs including legal costs on
          a full indemnity basis and expenses arising out of or in connection
          with any content you provide. To the extent permitted by law, you
          release and discharge us from any liability or claim arising out of
          any loss or damage that may be suffered or incurred as a result of
          your use of our Application.
          <br />
          <p className="orangeFont">
            Article 9 - INTELLECTUAL PROPERTY RIGHTS{" "}
          </p>
          <br />
          a) The copyright to all content on this Application including applets,
          graphics, images, layouts and text belongs to us. All trademarks,
          brands and logos which are used on this Application are owned by us.
          Your access to our Application does not license you to use those marks
          in any commercial way without our prior written permission. <br />
          b) Any information, comment, feedback, idea or suggestion (called
          “Comments”) which you provide to us through this Application becomes
          our property. If in future we use your Comments in promoting our
          Application or in any other way, we will not be liable for any
          similarities which may appear from such use. Furthermore, you agree
          that we are entitled to use your Comments for any commercial or
          non-commercial purpose without compensation to you or to any other
          person who has transmitted your Comments. If you provide us with
          Comments, you acknowledge that you are responsible for the content of
          such material including its legality, originality and copyright.{" "}
          <br />
          <p className="orangeFont">Article 10 - YOUR CONDUCT </p>
          <br />
          You agree not to use the application its content or Services for any
          unlawful purpose or any purpose prohibited under the laws and this
          clause. You further agree not to use the application its content and
          Services in any way that could damage the Application or its content
          and Services. We expects, and you the user not to: <br />
          a) Defame, abuse, harass, stalk, threaten or otherwise violate the
          legal rights such as privacy and publicity rights of others. <br />
          b) Upload data content protected by intellectual property laws (or
          publicity privacy rights) unless you own or control the rights. <br />
          c) Download or infringe any data uploaded by other users.
          <br />
          d) Falsify or delete the author's attribution, or other material
          contained in the uploaded data.
          <br /> e) Limit or prevent other users from using and enjoying the
          application functions and Services.
          <br /> f) Violate any code of conduct or other guidelines that applies
          to the use of application. <br />
          g) Harvest or collect information about other people, including e-mail
          addresses, without their consent.
          <br /> h) Violate any applicable law or regulation. <br />
          <p className="orangeFont">Article 11 - SECURITY </p> You agree not to
          undertake any of the following actions: <br />
          a) Reverse engineer, or attempt to reverse engineer or disassemble any
          code from or on the Application and infringe its functions or
          Services;
          <br /> b) Violate the security of application its content or Services
          through any unauthorized access, circumvention of encryption or other
          security tools, data mining or interference to any host, user or
          network.
          <br />
          <p className="orangeFont">Article 12 - DISCLAIMER</p> <br />
          a) Whilst we take all due care in providing our services, we do not
          provide any warranty either express or implied including without
          limitation warranties of merchantability or fitness for a particular
          purpose. <br />
          b) All the product warranty and guarantee are provided by the
          respective brand and on their sole discretion, we hold no claim
          concerning the warranty and Guarantee of the product and neither claim
          nor provide any product services from out end, our app provide only
          the service to raise a fulty product issue to the concerned brand{" "}
          <br />
          c)Though we provide all the functions available in the app but due to
          technical error any function may not work sometime, we disclaims all
          warranty that the app will be error free and meets all your
          expectations. No fees will be charged for the unused function.
          <br /> d) To the extent permitted by law, any condition or warranty
          which would otherwise be implied into these terms and conditions is
          excluded. We also take all due care in ensuring that our Application
          is free of any virus, worm, Trojan horse and/or malware, however, we
          are not responsible for any damage caused which arises in connection
          with your use of our Application. <br />{" "}
          <p className="orangeFont">Article 13 - LIMITATION OF LIABILITY</p>
          To the full extent permitted by law, our liability for breach of an
          implied warranty or condition is limited to the supply of the services
          again or payment of the costs of having those services supplied again.
          We accept no liability for any loss whatsoever including consequential
          loss suffered by you arising from functions and services of the
          application we have supplied.
          <br /> <p className="orangeFont">Article 14 - INDEMNITY </p>By
          accessing our Application, you agree to indemnify and hold us harmless
          from all claims, actions, damages, costs and expenses including legal
          fees arising from or in connection with your use of our Application.
          <br />
          <p className="orangeFont">Article 15 - JURISDICTION </p>
          <br />
          a) These terms and conditions are to be governed by and construed in
          accordance with the laws of India and any claim made by either party
          against the other which in any way arises out of these terms and
          conditions will be heard in Hyderabad India and you agree to submit to
          the jurisdiction of those Courts.
          <br /> b) If any provision in these terms and conditions is invalid
          under any law the provision will be limited, narrowed, construed or
          altered as necessary to render it valid but only to the extent
          necessary to achieve such validity. If necessary the invalid provision
          will be deleted from these terms and conditions and the remaining
          provisions will remain in full force and effect.
          <br /> <p className="orangeFont">Article 16 - PRIVACY</p> <br />
          We undertake to take all due care with any information which you may
          provide to us when accessing our Application. However, we do not
          warrant and cannot ensure the security of any information which you
          may provide to us. Information you transmit to us is entirely at your
          own risk although we undertake to take reasonable steps to preserve
          such information in a secure manner. Our compliance with privacy
          legislation is set out in our separate Privacy Policy which may be
          accessed from the application.
          <br /> <p className="orangeFont">Article 17 - GENERAL PROVISIONS</p>
          <br /> a) ASSIGNMENT: This Agreement, or the rights granted hereunder,
          may not be assigned, sold, leased or otherwise transferred in whole or
          part by you. Should this Agreement, or the rights granted hereunder by
          assigned, sold, leased or otherwise transferred by us, our rights and
          liabilities will bind and inure to any assignees, administrators,
          successors, and executors.
          <br />
          b) SEVERABILITY: If any part or sub-part of this Agreement is held
          invalid or unenforceable by a court of law or competent arbitrator,
          the remaining parts and sub-parts will be enforced to the maximum
          extent possible. In such condition, the remainder of this Agreement
          shall continue in full force. <br />
          c) NO WAIVER: In the event, we fail to enforce any provision of this
          Agreement; this shall not constitute a waiver of any future
          enforcement of that provision or any other provision. Waiver of any
          part or sub-part of this Agreement will not constitute a waiver of any
          other part or sub-part. <br />
          d) RELATIONSHIP: No agency, partnership, or joint venture has been
          created between the Parties as a result of this Agreement. No Party
          has any authority to bind the other to third parties. <br />
          e) FORCE MAJEURE: We are not liable for any failure to perform due to
          causes beyond its reasonable control including, but not limited to,
          acts of God, acts of civil authorities, acts of military authorities,
          riots, embargoes, acts of nature and natural disasters, and other acts
          which may be due to unforeseen circumstances. <br />
          f) COMMUNICATIONS: Both the parties to this agreement can communicate
          through e-mail.
          <br /> For any questions or concerns, please email us at the following
          address: info@warrantyme.co
          <br /> EFFECTIVE DATE: 12TH FEBRUARY 2020.
          <br />
        </p>
      </div>
      <div className="bottompage">
        <BottomBar />
      </div>
    </div>
  );
}
