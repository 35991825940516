import React from "react";
import "./Achievements.css";
import badge from "./../../assets/badge.svg";
import { Avatar, Divider } from "@mui/material";

const achievementsList = [
  {
    id: 1,
    header: "Winner",
    description: "Social Media Impact",
    desc2: "Award",
    by: "Hednxt, Krazybee",
    image: badge,
  },
  {
    id: 2,
    header: "Top 10 Nominee",
    description: "ReTech Startup",
    desc2: "Awards",
    by: "RAI",
    image: badge,
  },
  {
    id: 3,
    header: "Grantee",
    description: "T.I.D.E 2.0",
    desc2: "Grant",
    by: "MeiTy",
    image: badge,
  },
  {
    id: 4,
    header: "Grantee",
    description: "T.I.D.E 2.0",
    desc2: "Grant",
    by: "MeiTy",
    image: badge,
  },
];

export default function Achievements() {
  return (
    <div className="achCont">
            <div className="itemCont">
              <Avatar
                variant="square"
                src={achievementsList[0].image}
                sx={{ width: 44, height: 56 }}
              />
              <p className="itemHeader">{achievementsList[0].header}</p>
              <p className="itemDesc">{achievementsList[0].description}</p>
              <p className="itemDesc2">{achievementsList[0].desc2}</p>
              <p className="itemBy">{achievementsList[0].by}</p>
            </div>
            <div className="divider"></div>
            <div className="itemCont">
              <Avatar
                variant="square"
                src={achievementsList[1].image}
                sx={{ width: 44, height: 56 }}
              />
              <p className="itemHeader">{achievementsList[1].header}</p>
              <p className="itemDesc">{achievementsList[1].description}</p>
              <p className="itemDesc2">{achievementsList[1].desc2}</p>
              <p className="itemBy">{achievementsList[1].by}</p>
            </div>
            <div className="divider"></div>
            <div className="itemCont">
              <Avatar
                variant="square"
                src={achievementsList[2].image}
                sx={{ width: 44, height: 56 }}
              />
              <p className="itemHeader">{achievementsList[2].header}</p>
              <p className="itemDesc">{achievementsList[2].description}</p>
              <p className="itemDesc2">{achievementsList[2].desc2}</p>
              <p className="itemBy">{achievementsList[2].by}</p>
            </div>
            {/* <div className="divider"></div>
            <div className="itemCont">
              <Avatar
                variant="square"
                src={achievementsList[3].image}
                sx={{ width: 44, height: 56 }}
              />
              <p className="itemHeader">{achievementsList[3].header}</p>
              <p className="itemDesc">{achievementsList[3].description}</p>
              <p className="itemDesc2">{achievementsList[3].desc2}</p>
              <p className="itemBy">{achievementsList[3].by}</p>
            </div> */}
    </div>
  );
}
