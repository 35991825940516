import React from "react";
import "./MaterialCarousel.css";
import Carousel from "react-material-ui-carousel";
import homecarousel1 from "./../../assets/homecarousel1.png";
import homecarousel2 from "./../../assets/homecarousel2.png";
import homecarousel3 from "./../../assets/homecarousel3.png";
import { Avatar } from "@mui/material";

const item = [
  {
    id: 1,
    header: "All your warranties in one place",
    descrption:
      "We understand your love for gadgets. But, is managing warranties of so many devices intimidating you? Worry not, we got you covered. Now you can track warrantie`s of all your products in one place",
    image: homecarousel1,
  },
  {
    id: 2,
    header: "Never lose an invoice again",
    descrption:
      "So many papers and invoices flying around? At WarrantyMe, we are listening to you.  Now you can store all your invoices in one place. What's even more exciting you ask? We'll provide you all the FAQs, brand contacts and locations of service centres around you. All at your fingertips.",
    image: homecarousel2,
  },
  {
    id: 3,
    header: "One Click Warranty Claim",
    descrption:
      "Hey You!, got a swanky new device that is troubling you? Relax, just open the WarrantyMe app and tap on claim warranty. We will make sure that your device gets fixed under warranty, while you seize the day",
    image: homecarousel3,
  },
];

export default function MaterialCarousel() {
  return (
    <div>
      <div style={{ minHeight: "700px", marginTop: "70px" }} className="carouselWeb">
        <Carousel
          height="700px"
          indicatorIconButtonProps={{
            style: {
              backgroundColor: "white",
              color: "white",
              marginLeft: "12px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#FD6317",
              color: "#FD6317",
              marginLeft: "12px",
            },
          }}
          indicatorContainerProps={{
            style: {
              marginTop: "5px",
            },
          }}
          NextIcon={null}
          PrevIcon={null}
        >
          <div
            className="carouselItem"
            style={{
              minWidth: "763px",
              maxWidth: "763px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
              marginLeft: "calc(50vw - 381px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[0].image}
              sx={{ width: 172, height: 369 }}
              className="carouselImage"
            />
            <div className="packageType">{item[0].header}</div>
            <div className="packageAmount">{item[0].descrption}</div>
          </div>
          <div
            className="carouselItem"
            style={{
              minWidth: "763px",
              maxWidth: "763px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
              marginLeft: "calc(50vw - 381px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[1].image}
              sx={{ width: 172, height: 369 }}
              className="carouselImage"
            />
            <div className="packageType">{item[1].header}</div>
            <div className="packageAmount">{item[1].descrption}</div>
          </div>
          <div
            className="carouselItem"
            style={{
              minWidth: "763px",
              maxWidth: "763px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
              marginLeft: "calc(50vw - 381px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[2].image}
              sx={{ width: 172, height: 369 }}
              className="carouselImage"
            />
            <div className="packageType">{item[2].header}</div>
            <div className="packageAmount">{item[2].descrption}</div>
          </div>
        </Carousel>
      </div>
      <div style={{ minHeight: "700px", marginTop: "30px" }} className="carouselMobile">
        <Carousel
          height="700px"
          indicatorIconButtonProps={{
            style: {
              backgroundColor: "white",
              color: "white",
              marginLeft: "8px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#FD6317",
              color: "#FD6317",
              marginLeft: "8px",
            },
          }}
          indicatorContainerProps={{
            style: {
              marginTop: "5px",
            },
          }}
          NextIcon={null}
          PrevIcon={null}
        >
          <div
            className="carouselItem"
            style={{
              minWidth: "100vw",
              minHeight: "600px",
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "50px",
              // paddingBottom: "50px",
              // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Avatar
              variant="square"
              src={item[0].image}
              sx={{ width: 172, height: 369 }}
            />
            <div className="packageType">{item[0].header}</div>
            <div className="packageAmount">{item[0].descrption}</div>
          </div>
          <div
            className="carouselItem"
            style={{
              minWidth: "100vw",
              minHeight: "600px",
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "50px",
              // paddingBottom: "50px",
              // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Avatar
              variant="square"
              src={item[1].image}
              sx={{ width: 172, height: 369 }}
            />
            <div className="packageType">{item[1].header}</div>
            <div className="packageAmount">{item[1].descrption}</div>
          </div>
          <div
            className="carouselItem"
            style={{
              minWidth: "100vw",
              minHeight: "700px",
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "50px",
              // paddingBottom: "50px",
              // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Avatar
              variant="square"
              src={item[2].image}
              sx={{ width: 172, height: 369 }}
            />
            <div className="packageType">{item[2].header}</div>
            <div className="packageAmount">{item[2].descrption}</div>
          </div>
        </Carousel>
      </div>
      <div style={{ minHeight: "1300px", marginTop: "140px" }} className="carouselDesktop">
        <Carousel
          height="1300px"
          indicatorIconButtonProps={{
            style: {
              backgroundColor: "white",
              color: "white",
              marginLeft: "14px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#FD6317",
              color: "#FD6317",
              marginLeft: "14px",
            },
          }}
          indicatorContainerProps={{
            style: {
              marginTop: "-185px",
            },
          }}
          NextIcon={null}
          PrevIcon={null}
        >
          <div
            className="carouselItem"
            style={{
              minWidth: "963px",
              maxWidth: "963px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
              marginLeft: "calc(50vw - 481px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[0].image}
              sx={{ width: 272, height: 560 }}
              className="carouselImage"
            />
            <div className="packageType">{item[0].header}</div>
            <div className="packageAmount">{item[0].descrption}</div>
          </div>
          <div
            className="carouselItem"
            style={{
              minWidth: "963px",
              maxWidth: "963px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
              marginLeft: "calc(50vw - 481px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[1].image}
              sx={{ width: 272, height: 560 }}
              className="carouselImage"
            />
            <div className="packageType">{item[1].header}</div>
            <div className="packageAmount">{item[1].descrption}</div>
          </div>
          <div
            className="carouselItem"
            style={{
              minWidth: "963px",
              maxWidth: "963px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
              marginLeft: "calc(50vw - 481px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[2].image}
              sx={{ width: 272, height: 560 }}
              className="carouselImage"
            />
            <div className="packageType">{item[2].header}</div>
            <div className="packageAmount">{item[2].descrption}</div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}
