import React from "react";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { useNavigate } from "react-router-dom";

const Calendly = () => {
  const navigate = useNavigate();
  useCalendlyEventListener({
    onEventScheduled: (e) => {
      console.log(e.data.payload);
      setTimeout(() => {
        navigate("/");
      }, 5000);
    },
  });

  return (
    <div className="App">
      <InlineWidget url="https://calendly.com/rchetla/warrantyme-demo" />
    </div>
  );
};

export default Calendly;
