import React from "react";
import "./ContactTab.css";
import Navbar from "../../components/Navbar/Navbar";

export default function ContactTab() {
  return (
    <div>
      <Navbar />
      <div style={{ padding: "100px" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p style={{fontWeight: "bold"}}>Address:</p>
          <p style={{marginLeft: "20px"}}>2-10-1010, Jyothi Nagar, Karimnagar, Telangana, India, 505001</p>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p  style={{fontWeight: "bold"}}>TelePhone:</p>
          <p  style={{marginLeft: "20px"}}>+91-9100209646</p>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p  style={{fontWeight: "bold"}}>EmailId:</p>
          <p  style={{marginLeft: "20px"}}>info@warrantyme.co</p>
        </div>
      </div>
    </div>
  );
}
