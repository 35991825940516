import React from 'react'
import ScrollTop from './components/ScrollTop/ScrollTop'
import Home from './pages/Home/Home'
import AboutUs from './pages/AboutUs/About'
import Advisors from './pages/Advisors/Advisors'
import Business from './pages/Business/Business'
import Calendly from './components/Calendly/Calendly'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import Terms from './pages/TermsConditions/Terms'
import ContactTab from './pages/ContactTab/ContactTab'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
       <ScrollTop />
      <Routes>
        <Route path='/' element={<Home/>}>
        </Route>
        <Route path='/aboutus' element={<AboutUs/>}>
        </Route>
        <Route path='/advisors' element={<Advisors/>}>
        </Route>
        <Route path='/business' element={<Business/>}>
        </Route>
        <Route path='/calendly' element={<Calendly />}>
        </Route>
        <Route path='/privacy-policy' element={<PrivacyPolicy />}>
        </Route>
        <Route path='/terms' element={<Terms />}>
        </Route>
        <Route path='/contact-us' element={<ContactTab />}>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;