import React, { useState } from "react";
import "./Contactus.css";
import appleappstore from "./../../assets/ioslogo.svg";
import playstore from "./../../assets/androidlogo.svg";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
// import ScrollToTop from "react-scroll-to-top";
// import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

export default function Contactus() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(true);

  function submitForm() {
    console.log("name, number, email, message", name, number, email, message);
    setOpen(true); //temp
    // axios
    //   .post("", {})
    //   .then(function (response) {
    //     console.log(response);
    //     setStatus(true);
    //     setOpen(true);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //     setStatus(false);
    //     setOpen(true);
    //   });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="contactUsDiv">
      <div className="tryDiv">
        <p className="contactLine">Try WarrantyMe</p>
        <p className="contactLine2">
          For <span className="colorOrange">Free!</span>
        </p>
        <p className="homeMiddleHeading">Download our app now</p>
        <div
          className="homeBottomContainerContact"
          style={{ marginTop: "2vw" }}
        >
          <a
            href="https://apps.apple.com/us/app/warrantyme/id1667166843"
            target="_blank"
          >
            <div className="storeCont">
              <img
                src={appleappstore}
                alt="ios"
                className="storeimage"
                style={{ width: "38px", height: "41px" }}
              />
              <p className="storeText">Download on the</p>
              <p className="storeHeaderText">App Store</p>
            </div>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.wmecm2"
            target="_blank"
          >
            <div className="storeCont" style={{ marginLeft: "17px" }}>
              <img src={playstore} alt="android" className="storeimage" />
              <p className="storeText">Get in on</p>
              <p className="storeHeaderText">Google Play</p>
            </div>
          </a>
        </div>
      </div>
      <div className="contactUsBoxDiv">
        <p className="contactUsTitle">Contact Us</p>
        <TextField
          id="standard-basic"
          label="Name"
          variant="standard"
          InputLabelProps={{
            style: {
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "27px",
              opacity: 0.4,
            },
          }}
          sx={{
            "& .MuiInput-underline:before": { borderBottomColor: "#FFFFFF" },
            "& .MuiInput-underline:after": { borderBottomColor: "#FD6317" },
            "& .MuiInput-root": { p: 1, color: "white" },
          }}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <TextField
          id="standard-basic"
          label="Email"
          variant="standard"
          type="email"
          InputLabelProps={{
            style: {
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "27px",
              opacity: 0.4,
            },
          }}
          sx={{
            "& .MuiInput-underline:before": { borderBottomColor: "#FFFFFF" },
            "& .MuiInput-underline:after": { borderBottomColor: "#FD6317" },
            "& .MuiInput-root": { p: 1, color: "white" },
          }}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <TextField
          id="standard-basic"
          label="Mobile"
          type="tel"
          variant="standard"
          InputLabelProps={{
            style: {
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "27px",
              opacity: 0.4,
            },
          }}
          sx={{
            "& .MuiInput-underline:before": { borderBottomColor: "#FFFFFF" },
            "& .MuiInput-underline:after": { borderBottomColor: "#FD6317" },
            "& .MuiInput-root": { p: 1, color: "white" },
          }}
          onChange={(event) => {
            setNumber(event.target.value);
          }}
        />
        <TextField
          id="standard-basic"
          label="Type Your Message Here"
          variant="standard"
          InputLabelProps={{
            style: {
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "27px",
              opacity: 0.4,
            },
          }}
          sx={{
            "& .MuiInput-underline:before": { borderBottomColor: "#FFFFFF" },
            "& .MuiInput-underline:after": { borderBottomColor: "#FD6317" },
            "& .MuiInput-root": { p: 1, color: "white" },
          }}
          InputProps={{
            padingBottom: "18px",
          }}
          size="medium"
          onChange={(event) => {
            setMessage(event.target.value);
          }}
        />
        <Button
          className="contactSubmitButton"
          variant="contained"
          sx={{
            position: "absolute",
            bottom: "49px",
            right: "42px",
            backgroundColor: "#FD6317",
            borderRadius: "10px",
            "&:hover": { backgroundColor: "#FD6317" },
            textTransform: "none",
            "@media (max-width: 720px)": {
              right: "calc(50vw - 96px)",
            },
          }}
          onClick={() => {
            submitForm();
          }}
        >
          Submit
        </Button>
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle
            sx={{
              width: "400px",
              height: "200px",
              textAlign: "center",
              paddingTop: "88px",
              color: "#fd6317",
              // backgroundColor: "#16191e",
              borderRadius: "4px",
            }}
          >
            {status ? "Your message has been sent!" : "Something Went wrong."}
          </DialogTitle>
        </Dialog>
      </div>
    </div>
  );
}
