import { React } from "react";
import Navbar from "./../../components/Navbar/Navbar";
import BottomBar from "./../../components/BottomBar/BottomBar";
import Contactus from "../ContactUs/Contactus";
import { Card, Row, Col, Container } from "react-bootstrap";
import Aravind from "./../../assets/Aravind.jpg";
import Priyanka from "./../../assets/persons/Priyanka.png";
import Rohit from "./../../assets/persons/rohit.jpg";
import Prasad from "./../../assets/persons/prasad.jpg";
import Ramana from "./../../assets/persons/ramana.png";
import Santhosh from "./../../assets/persons/Santhosh.jpg";
import pankaj from "./../../assets/persons/pankaj.jpg";
import piyush from "../../assets/persons/piyush.jpg";
import Mallikarjun from "../../assets/persons/Mallikarjun.jpg";
import Ashwik from "../../assets/persons/Ashwik.png";
import Atanu from "../../assets/persons/Atanu.jpg";
import Anusha from "../../assets/persons/Anusha.jpg";
import Harshith from "../../assets/persons/Harshith.png";

import "./About.css";

const About = () => {
  const items = [
    {
      name: "Rohit Sai Chetla",
      designation: "(Founder, CEO)",
      description: "A plethora of innovation, Leader and a Tech Nerd",
      image: Rohit,
    },
    {
      name: "Venkat Ramana Bandari",
      designation: "(Chief Engineer)",
      description: "A plethora of innovation, Leader and a Tech Nerd",
      image: Ramana,
    },
    {
      name: "Atanu",
      designation: "(Machine Learning Engineer)",
      description: "A plethora of innovation, Leader and a Tech Nerd",
      image: Atanu,
    },
    {
      name: "Piyush Chatterjee",
      designation: "(Front-End Developer)",
      description: "A plethora of innovation, Leader and a Tech Nerd",
      image: piyush,
    },
    {
      name: "Santhosh Kanthala",
      designation: "(Product Designer)",
      description: "A plethora of innovation, Leader and a Tech Nerd",
      image: Santhosh,
    },

    {
      name: "Durga Prasad",
      designation: "(Team Lead)",
      description: "A plethora of innovation, Leader and a Tech Nerd",
      image: Prasad,
    },
    {
      name: "Pankaj Kumar",
      designation: "(Senior Developer)",
      description: "A plethora of innovation, Leader and a Tech Nerd",
      image: pankaj,
    },
    {
      name: "Priyanka Pugalia",
      designation: "(General Manager)",
      description: "A plethora of innovation, Leader and a Tech Nerd",
      image: Priyanka,
    },

    {
      name: "Mallikarjun",
      designation: "(Software Tester)",
      description: "A plethora of innovation, Leader and a Tech Nerd",
      image: Mallikarjun,
    },

    {
      name: "Ashwik Ram Konda",
      designation: "(Social Media Marketer)",
      description: "A plethora of innovation, Leader and a Tech Nerd",
      image: Ashwik,
    },
  ];

  return (
    <div className="aboutUsDiv">
      <Navbar />
      {/* <div className="aboutUsContent">About WarrantyMe</div>
      <div>
        <p style={{ paddingLeft: "110px", paddingRight: "90px", textAlign: "justify", textJustify: "inter-word", marginTop: "30px"}}>
          WarrantyMe was started in the year 2018, by an undergrad engineer who
          saw a gap/opportunity in the consumer electronic warranty and service
          space, and instead of copying existing business models, we innovated
          and created a whole new business model which helps people to track
          warranties of all their products at their fingertips and at the same
          time enables consumer electronic retailers to improve their business
          by opening an additional revenue stream and offering digitisation
          services.
        </p>
      </div> */}
      <div className="aboutUsContent">We are group of cyber and technology</div>
      <div className="aboutUsLine">
        <p>
          Meet our <span className="colorOrange">Team</span>
        </p>
      </div>
      <div className="aboutContainerTop">
        <Container>
          <Row className="displayWeb">
            {items.map((item, k) => (
              <Col key={k} xs={10} sm={6} md={6} lg={6} xl={3} xxl={4}>
                <Card className="cardDiv">
                  <Card.Img src={item.image} className="cardDetails-img" />
                  <Card.Body className="cardDetails">
                    <Card.Title className="cardName">{item.name}</Card.Title>
                    <Card.Text className="cardDesignation">
                      {item.designation}
                    </Card.Text>
                    {/* <Card.Text className='cardDescription'>{item.description}</Card.Text> */}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className="contactUsContainer">
        <Contactus />
      </div>
      <BottomBar />
    </div>
  );
};
export default About;
