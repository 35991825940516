import React, { useRef } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import Navbar from "./../../components/Navbar/Navbar";
import BottomBar from "./../../components/BottomBar/BottomBar";
import Contactus from "../ContactUs/Contactus";
import Carousel from "../Carousel/Carousel";
import MaterialCarousel from "../Carousel/MaterialCarousel";
import Achievements from "../Achievements/Achievements";
import homeimage from "./../../assets/illustration1.svg";
import appleappstore from "./../../assets/ioslogo.svg";
import playstore from "./../../assets/androidlogo.svg";
import earth from "./../../assets/earth.svg";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const Home = () => {
  const carouselRef = useRef(null);

  function scrollCarousel() {
    carouselRef.current.scrollIntoView();
    console.log("carouselRef.current: ", carouselRef.current);
  }

  return (
    <div className="homediv">
      <Navbar scrollCarousel={scrollCarousel} />
      <div className="homeContent" style={{ marginTop: "80px" }}>
        <div className="homeContainer">
          <div className="homeTopContainer">
            <h2 className="homeheading">
              {" "}
              One stop <br className="onlyWeb" />
              solution <br className="onlyMob" /> to all{" "}
              <br className="onlyWeb" /> your <br className="onlyMob" />{" "}
              warranty
              <br className="onlyWeb" /> needs
            </h2>
            <img src={homeimage} alt="" className="homeimage" />
          </div>
          <div className="homeMiddleContainer">
            <p className="homeMiddleHeading">Download our app now</p>
          </div>
          <div className="homeBottomContainer" style={{ marginTop: "2vw" }}>
            <a
              href="https://apps.apple.com/us/app/warrantyme/id1667166843"
              target="_blank"
            >
              <div className="storeCont" style={{ marginLeft: "6.2vw" }}>
                <img
                  src={appleappstore}
                  alt="ios"
                  className="storeimage"
                  style={{ width: "38px", height: "41px" }}
                />
                <p className="storeText">Download on the</p>
                <p className="storeHeaderText">App Store</p>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.wmecm2"
              target="_blank"
            >
              <div className="storeCont" style={{ marginLeft: "17px" }}>
                <img src={playstore} alt="android" className="storeimage" />
                <p className="storeText" style={{ textDecoration: "none" }}>
                  Get in on
                </p>
                <p className="storeHeaderText">Google Play</p>
              </div>
            </a>
          </div>
          <div className="homeLowerContainer" ref={carouselRef}>
            Too many gadgets to manage?
            <div className="homeLowerContainer2">
              <p>
                Voila!!!{" "}
                <span className="colorOrange">
                  WarrantyMe <br className="onlyMob" />
                </span>{" "}
                is here to help you.
              </p>
            </div>
          </div>
          {/* <div className="homeLowerContainer3" ref={carouselRef}>
            <Carousel />
          </div> */}
          <div className="homeLowerContainer3">
            <MaterialCarousel />
          </div>
          <div className="bottomContainer1">
            These are some of
            <div className="bottomContainer2">
              <p>
                Our<span className="colorOrange"> Achievements</span>
              </p>
            </div>
          </div>
          <div className="homeAchievements">
            <Achievements />
          </div>
          <div className="bottombanner">
            <div className="bottombannerleft">
              <div className="bottomleft">
                <p className="bottomleftheading">Why we're doing this?</p>
                <p className="bottomleftcontent">
                  Every year 50 Million tones of electronic devices are thrown
                  away by humans which end up in the landfills and 3.5 Billion
                  trees are cut down to print paper invoices/receipts for newly
                  purchased electronic devices. We are trying to solve both of
                  these problems with WarrantyMe, WarrantyMe partners with
                  retail stores to enable digitised invoices reducing their
                  paper usage to zero, and using our digitised platform, we help
                  our customers track, maintain their electronic devices
                  efficiently so that they can keep them longer.
                </p>
                <p className="bottomleftcontent strikeText">
                  E-waste Paper Waste
                </p>
              </div>
            </div>
            <div className="bottombannerright">
              <img src={earth} alt="" className="homeRightImg"></img>
            </div>
          </div>
          <div className="contactUsContainer">
            <Contactus />
          </div>
          {/* <div className='scrollTopCont'>
             <div className='scrollTopIconCont'>
               <KeyboardDoubleArrowUpIcon />
               <p>Back To Top</p>
             </div>
          </div> */}
        </div>
      </div>
      <BottomBar />
    </div>
  );
};
export default Home;
