import React from "react";
import ScrollToTop from "react-scroll-to-top";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import "./ScrollTop.css";

export default function ScrollTop() {
  return (
    <div className="scrollTopCont">
      <ScrollToTop
        smooth
        component={
          <div>
            <KeyboardDoubleArrowUpIcon />
          </div>
        }
      />
    </div>
  );
}
