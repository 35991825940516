import React from "react";
import { useNavigate } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import ScrollToTop from "react-scroll-to-top";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import LaunchIcon from "@mui/icons-material/Launch";
import "./bottomBar.css";

const BottomBar = () => {
  const navigate = useNavigate();

  return (
    <div className="bottomBar">
      <div className="bottomSocial">
        <a
          target="_blank"
          href="https://www.instagram.com/warrantyme/?hl=en"
          className="extLinkIcon"
        >
          <InstagramIcon />
        </a>
        <a
          target="_blank"
          href="https://m.facebook.com/WarrantyMe/"
          className="extLinkIcon"
        >
          <FacebookIcon />
        </a>
        <a
          target="_blank"
          href="https://mobile.twitter.com/mewarranty"
          className="extLinkIcon"
        >
          <TwitterIcon />
        </a>
      </div>
      <div className="contactDetails">
        <p>
          Email Us: <span className="cdCapital">info@warrantyme.co</span> | Tel:{" "}
          <span className="cdCapital">9100209646 </span>
        </p>
      </div>
      <div className="hrLine">
        <hr className="lineWidth"></hr>
      </div>
      <div className="copyrightLine">
        <p className="copyrightText">
          Copyright © 2022 Dragua RSM Technologies Private Limited. All Rights
          Reserved. I Terms of Use I Privacy Policy I EULA
        </p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <div
          onClick={() => {
            navigate("/privacy-policy");
          }}
          style={{
            textAlign: "center",
            textDecoration: "underline",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{fontSize: "20px", marginTop: "10px"}}>Privacy Policy</p>
          <LaunchIcon />
        </div>
        <div
          onClick={() => {
            navigate("/terms");
          }}
          style={{
            textAlign: "center",
            textDecoration: "underline",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "20px"
          }}
        >
          <p style={{fontSize: "20px", marginTop: "10px"}}>Terms and Conditions</p>
          <LaunchIcon />
        </div>
      </div>
    </div>
  );
};

export default BottomBar;
