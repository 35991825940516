import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./../../components/Navbar/Navbar";
import BottomBar from "./../../components/BottomBar/BottomBar";
import Features from "../Features/Features";
// import Carousel from '../Carousel/Carousel';
import BusinessCarousel from "../BusinessCarousel/BusinessCarousel";
import MaterialBusinessCarousel from "../BusinessCarousel/MaterialBusinessCarousel";
import business from "./../../assets/business.svg";
// import ScrollToTop from "react-scroll-to-top";
// import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import "./business.css";

export default function Business() {
  return (
    <div className="busDiv">
      <Navbar />
      <div className="businessContent">
        <div className="businessContainer">
          <p className="businessHeading">
            Increase Sales
            <br className="onlyIpad" /> Reduce Costs
            <br className="onlyIpad" /> Get Customers for life
            <br className="onlyIpad" />
          </p>
          <div>
            <Link to="/calendly">
              <button className="requestButtonContent">Request A Demo</button>
            </Link>
          </div>
        </div>
        <div className="rightImgdiv">
          <img src={business} alt="" className="rightImg" />
        </div>
        <Link to="/calendly">
          <button className="requestButtonMobile">Request A Demo</button>
        </Link>
      </div>
      <div className="businessContent2">
        <div>
          <p className="bc2Heading">Love your customers?</p>
        </div>
        <div>
          <p className="bc2Heading2">
            Use <span className="colorOrange">WarrantyMe</span> and delight them
          </p>
        </div>
      </div>
      {/* <div className="homeLowerContainer3" >
        <BusinessCarousel />
      </div> */}
      <div className="homeLowerContainer3">
        <MaterialBusinessCarousel />
      </div>
      <div className="features">
        <Features />
      </div>
      <div className="bottomDemo">
        <div className="bottomDemoHeading">
          <p>
            Like what you see? <br className="onlyMob" /> Try WarrantyMe !{" "}
            <br className="onlyMob" />
          </p>
        </div>
        <div className="requestButtonBox">
          <Link to="/calendly">
            <button className="requestButton">Request A Demo</button>
          </Link>
        </div>
      </div>
      <div className="bottompage">
        <BottomBar />
      </div>
    </div>
  );
};
