import React from 'react';
import Navbar from './../../components/Navbar/Navbar'
import { Card, Row, Col, Container } from "react-bootstrap";
import BottomBar from "./../../components/BottomBar/BottomBar"
import Contactus from '../ContactUs/Contactus';

import Ajay from './../../assets/persons/Ajay.jpg'
import Ranjit from './../../assets/persons/Ranjit.jpg'
import Bhavish from './../../assets/persons/Bhavish.jpg'
import Shivadhar from './../../assets/persons/Shivadhar.jpg'
import Venugopal from './../../assets/persons/Venugopal.jpg'
import bsrao from './../../assets/persons/bsrao.png'

import "./advisors.css"

const Advisors = () =>{
  const items = [
    {
      name:"Ajay Aggarwal",
      designation:"(Managing Partner)",
      company: "100watts.tech",
      img: Ajay
    },
    {
      name:"Ranjit Satyanath",
      designation:"(Former CIO)",
      company: "Croma",
      img: Ranjit
    },
    {
      name:"Bhavish Sood",
      designation:"(Partner)",
      company: "Modular Capital",
      img: Bhavish
    },
    {
      name:"Shivadhar Soma",
      designation:"(Enterprise Sales)",
      company: "MessageBird",
      img: Shivadhar
    },
    {
      name:"Venugopal Kandimalla",
      designation:"(Vice President)",
      company: "Persistent Systems",
      img: Venugopal
    },
    {
      name:"BS Rao",
      designation:"(CEO)",
      company: "SRiX",
      img: bsrao
    }
  ]

  return (
    <div  className='aboutUsDiv'>
      <Navbar />
      <div className="aboutUsContent">
        Join Hunters to solve 
      </div>
      <div className="aboutUsLine">
        <p>Meet our <span className='colorOrange'>Advisors</span></p>
      </div>
      <div className="aboutContainerTop">
        <Container>
              <Row className="displayWeb">
                  {items.map((item, k) => (
                      <Col key={k} xs={10} sm={6} md={6} lg={6} xl={3}  xxl={4} >
                          <Card className='cardDiv'>
                              <Card.Img src={item.img} className='cardDetails-img'/>
                                <Card.Body className="cardDetails">
                                    <Card.Title className='cardName'>{item.name}</Card.Title>
                                    <Card.Text className='cardDesignationAdvisors'>{item.designation}</Card.Text>
                                    <Card.Text className='cardDescriptionAdvisors'>{item.company}</Card.Text>
                                </Card.Body>
                          </Card>
                      </Col>
                  ))}
              </Row>
        </Container>
      </div>
      <div className="contactUsContainer">
        <Contactus />
      </div>
      <BottomBar />
    </div>
  );
}
export default Advisors;
