import React from "react";
import "./Features.css";
import feature1 from "./../../assets/feature1.svg";
import feature2 from "./../../assets/feature2.svg";
import feature3 from "./../../assets/feature3.svg";
import feature4 from "./../../assets/feature4.svg";
import { Avatar } from "@mui/material";

const FeatureItems = [
  {
    header: "Seperate Mobile App",
    image: feature1,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud aliquip ex ea commodo consequat. ",
  },
  {
    header: "Embedded Insurance and Extended Warranty",
    image: feature2,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud aliquip ex ea commodo consequat. ",
  },
  {
    header: "API ready for POS Integration ",
    image: feature3,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud aliquip ex ea commodo consequat. ",
  },
  {
    header: "Cost-effective pricing",
    image: feature4,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud aliquip ex ea commodo consequat. ",
  },
];
export default function Features() {
  return (
    <div className="rootFeatures rowFeatures">
      <div className="columnFeatures">
        <div className="rowFeatures topMargin">
          <Avatar
            variant="square"
            src={FeatureItems[0].image}
            sx={{ width: 56, height: 56 }}
          />
          <div className="columnFeatures" style={{ marginLeft: "3vw" }}>
            <p className="header">{FeatureItems[0].header}</p>
            {/* <p className="desc">{FeatureItems[0].description}</p> */}
          </div>
        </div>
        <div className="rowFeatures topMargin" style={{ marginTop: "35px", marginLeft: "5px"}}>
          <Avatar
            variant="square"
            src={FeatureItems[1].image}
            sx={{ width: 52, height: 52 }}
          />
          <div
            className="columnFeatures"
            style={{
              marginLeft: "3vw",
            }}
          >
            <p className="header">{FeatureItems[1].header}</p>
            {/* <p className="desc">{FeatureItems[1].description}</p> */}
          </div>
        </div>
      </div>
      <div
        className="columnFeatures leftMargin"
      >
        <div className="rowFeatures topMargin">
          <Avatar
            variant="square"
            src={FeatureItems[2].image}
            sx={{ width: 56, height: 56 }}
          />
          <div className="columnFeatures" style={{ marginLeft: "3vw" }}>
            <p className="header">{FeatureItems[2].header}</p>
            {/* <p className="desc">{FeatureItems[2].description}</p> */}
          </div>
        </div>
        <div className="rowFeatures topMargin"  style={{ marginTop: "5px", marginLeft: "5px"}}>
          <Avatar
            variant="square"
            src={FeatureItems[3].image}
            sx={{ width: 52, height: 52 }}
          />
          <div className="columnFeatures" style={{ marginLeft: "3vw" }}>
            <p className="header">{FeatureItems[3].header}</p>
            {/* <p className="desc">{FeatureItems[3].description}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
