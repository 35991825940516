import React from "react";
import "./PrivacyPolicy.css";
import Navbar from "../../components/Navbar/Navbar.jsx";
import BottomBar from "./../../components/BottomBar/BottomBar";
export default function PrivacyPolicy() {
  return (
    <div>
      <Navbar />
      <div className="privCont" style={{ color: "white", fontSize: "18px" }}>
        <strong
          style={{
            marginTop: "10px",
            marginBottom: "50px",
            fontSize: "32px",
            width: "100%",
          }}
        >
          <p style={{ width: "100%", textAlign: "center" }}>Privacy Policy</p>
        </strong>
        <p>
          <strong className="orangeFont">1. Protecting your privacy</strong>
        </p>
        <p>
          The WarrantyMe Application is owned and operated by Dragua RSM Info
          Solutions PVT LTD, which is committed to protecting the information
          about you that we collect, store and use when you provide it to us by
          using our application.
        </p>
        <p>
          This document describes how we manage any personal information we have
          collected about you from the Application.
        </p>
        <p>
          By accessing our Application, you accept the terms and conditions of
          its use and consent to the collection, use and disclosure of your
          personal information in accordance with this privacy policy and the
          terms and conditions of use of the Application.
        </p>
        <p>
          If you have questions or complaints regarding our privacy policy or
          practices, or believe that your privacy has been breached, please
          contact us at our e- mail and provide details of the incident so that
          we can investigate it. We request that all complaints be made in
          writing, so that we can be sure about the details of the complaint. We
          will treat your complaint confidentially, investigate your complaint
          and aim to ensure that we contact you and your complaint is resolved
          within a reasonable time &#40;and in any event within the time
          required by the Privacy Act, if applicable&#41;.
        </p>
        <p>
          <strong className="orangeFont">
            2. COLLECTION & USE OF PERSONAL INFORMATION
          </strong>
        </p>
        <p>
          What personal information about me does WarrantyMe hold, and how it is
          collected:
        </p>
        <p>
          <b>Registration process</b>
        </p>
        <p>
          When you register on the Application, to submit information about you
          to us such information includes:
          <br />
          Your name, if an individual or your company name &#40;as
          appropriate&#41;; Your contact details, such as your telephone number,
          physical address and email address; any other information collected
          via the WarrantyMe application. If you do not provide us with the
          necessary information, you will not be able to use our application
          services.
        </p>
        <p>
          If you have questions or complaints regarding our privacy policy or
          practices, or believe that your privacy has been breached, please
          contact us at our e- mail and provide details of the incident so that
          we can investigate it. We request that all complaints be made in
          writing, so that we can be sure about the details of the complaint. We
          will treat your complaint confidentially, investigate your complaint
          and aim to ensure that we contact you and your complaint is resolved
          within a reasonable time &#40;and in any event within the time
          required by the Privacy Act, if applicable&#41;.
        </p>
        <p>
          <b>User submitted content</b>
          <br />
          The Application allows you to post and submit content and when you do,
          we collect the information that you submit. If you post any content,
          pursuant to our terms and conditions of use, you authorise us to
          publish that content, to publish the name under which you submit the
          content and you indemnify us in respect of claims or losses resulting
          from our publication of that content.
        </p>
        <p>
          <b>Geo-Location Data</b>
          <br />
          We may ask to track location-based information from your mobile device
          while downloading or using our mobile apps or services we provide, in
          order to tailor your experience. If you don't grant permission then we
          will not collect any location-based data.
        </p>
        <p>
          <b>2.2 What does WarrantyMe do with my personal information?</b>
          <br />
          We collect, hold and use your personal information for a number of
          purposes, as outlined below:
          <br />
          In general, we use the information that we collect to: Provide the
          functions and services to you and operate the Application; Contact you
          via phone, writing or email in relation to your requests; Display,
          update and maintain the content of the Application; Tailor your
          experience of the Application and our communications to you; and Send
          information about the application, email communications or
          newsletters.
          <br />
          RETAILER APPLICATION: In the retailer application the app is provided
          to the retailer where they can collect the customers data like Name,
          Phone number, Email, Pin code, address, and birth date, it uses
          customers location, and access to camera to upload bills this data is
          reflected in the customers app which makes it easier for the
          executives to takes the details of the customer. When the retailer use
          this app the following retailer data is collected Retailer/Shop name,
          Location, Latitude, Longitude, Address, Contact number a Logo,
          Executive names and Manager names
          <br />
          CUSTOMER DATA FOR RETAILER: Through the retailer app the following
          customer data is saved in the app like the Name, Phone number, Pin
          code, address, email and the purchased Product details Data are
          entered into the application like <br />
          The Product name
          <br />
          Product Brand
        </p>
        <p className="mt-10">
          Model Number - A103842 &#40;Example&#41;
          <br />
          IMEI - AOEIR27777 &#40;Example&#41;
          <br />
          Uploads the Digital Invoice/Bill of the product
          <br />
          Invoice Date of Purchased products
          <br />
          Place of Purchase.
          <br />
          Warranty Period and Price of the product
          <br />
          The customer can also save their data in the app for their personal
          record
          <br />
          The Product name
          <br />
          Product Brand
          <br />
          Model Number - A103842 &#40;Example&#41;
          <br />
          IMEI - AOEIR277772 &#40;Example&#41;
          <br />
          Invoice Date of Purchased products
          <br />
          Invoice Date of Purchased products
          <br />
          Place of Purchase.
          <br />
          Warranty Period and Price of the product
          <br />
        </p>
        <p className="mt-10" style={{ marginBottom: "50px" }}>
          To whom will WarrantyMe disclose my personal information We do not
          disclose contact information about you publicly or to other third
          parties, unless:
          <br />
          it is authorised or required by law (or when we believe that
          disclosure is necessary to protect our rights and/or to comply with a
          judicial proceeding, court order, or legal process served on us in
          relation to the Application); Pursuant to an agreement with you; or As
          otherwise outlined in this policy
          <br />
          When necessary to provide a service to you, we will also disclose your
          personal information (on a confidential basis) that we use in the
          ordinary operation of our business, user experience research and
          surveys, Application and support and maintenance. We will only
          disclose your information to the extent required for the limited
          purpose of the third party providing services to you (in the case of
          participating educational institutions or training providers) or as
          contracted to us so that we may provide our services.
          <br />
          If WarrantyMe is involved in a merger, acquisition, or sale of all or
          a portion of its assets, your Personal Information or other
          information will be part of the transferred assets.
          <br /> We may also disclose aggregated data (in a form that does not
          identify you individually) to our internal company/external advisors
          for the purposes of conducting market and user experience analysis.
          <br /> While we store the majority of the personal information that we
          collect from you in India, we may from time to time store some of the
          information in a computer server located in the United States or
          elsewhere. <br />
          Manner of collection <br />
          We will use reasonable endeavours to only collect information directly
          from you. If you are disclosing details of another person, it is your
          sole responsibility to ensure that that person understands and
          consents to the collection, use and disclosure of their personal
          information in the manner set out in this policy.
          <br />
          <p>
            <strong className="orangeFont">
              {" "}
              3. INFORMATION COLLECTED BY OUR APPLICATION
            </strong>
          </p>
          <br /> Tracking of user experience:
          <br />
          As is true of most mobile applications, we gather certain information
          automatically and store it in log files. This information includes
          internet protocol (IP) addresses, browser type, internet service
          provider (ISP), referring/exit pages, date/time stamp.
          <br /> We use this information, which does not identify individual
          users, to analyse trends, to administer the Application, to track
          users' movements around the Application and to gather demographic
          information about our user base as a whole. Technologies such as
          cookies, beacons, tags and scripts (not limited to) are used for
          marketing and development purposes, online customer support provider,
          email marketing system. These technologies are used in analysing
          trends, administering the Application, tracking users' movements
          around the application and to gather demographic information about our
          user base. We may receive reports based on the use of these
          technologies by these companies on an individual as well as aggregated
          basis. We use cookies for authentication purposes and to tailor the
          Application to user preferences.
          <br /> We use Google Analytics Advertising products: Remarketing with
          Google Analytics and Google Analytics Demographics and Interest
          Reporting. Google Analytics collects data about our site traffic via
          Google Advertising cookies and anonymous identifiers. Data collected
          via these Google products is not linked with any personally
          identifiable information you submit while on our Application.
          <br /> If you wish to opt out of the Google Analytics data collection,
          you may do so on Google's Application. Some components of the
          Application utilises Google Maps mapping services. Your use of Google
          Maps is subject to Google's terms of use and Google's privacy policy,
          and may be amended by Google from time to time.
          <br />
          <p>
            <strong className="orangeFont">4. MARKETING MATERIAL</strong>
          </p>
          <br /> We may use your personal information to provide you with
          material, promotions and communications from time to time about
          services and products available to you from us, or from other service
          providers in partnership with us, such to invite you to continue to
          use our services. This information will only come from us. You may opt
          out of receiving promotional materials from us at any time by emailing
          our support team. <br />
          <p>
            <b className="mt-10 orangeFont">
              5. PROTECTING AND MAINTAINING PERSONAL INFORMATION
            </b>
          </p>
          <br /> We will take all reasonable steps to protect the information we
          hold about you from unauthorised access, use and disclosure. However,
          we cannot guarantee that our systems and stored data will be
          completely free from third party interception or are free from
          corruption.
          <br /> Certain parts of the Application such as those parts that
          require you to provide us with your billing and payment details
          provides the ability for you to transmit information to our
          Application in an encrypted form by using secure socket layer
          technology (SSL). Further, no method of transmission over the
          Internet, or method of electronic storage, is 100% secure. In light of
          this, we cannot ensure or warrant, and do not warrant, the security or
          privacy of your personal information, including payment and account
          details. You transmit this information at your own risk. If you have
          any questions regarding security you can contact us at our e-mail
          info@warrantyme.co <br />
          You are solely responsible for maintaining the security of your
          passwords and/or any account information. We will destroy or
          de-identify your personal information when it is no longer needed or
          required to be kept by law or to provide services to you. We will also
          preserve the content of any email or information you submit if we
          believe we have a legal requirement to do so, or if any form of email
          abuse is suspected.
          <br /> If you use our Application to link to another site, we
          encourage you to view their terms and conditions of use, privacy
          policies and security statements before disclosing any of your
          personal information on those sites. <br />
          <p>
            <strong className="orangeFont">
              6. ACCESSING PERSONAL INFORMATION
            </strong>
          </p>
          <br /> It is your responsibility to advise us of any changes to your
          personal details to ensure we can keep our records accurate and up to
          date. If you wish to access the information we hold about you, please
          submit a request to our email or by logging into your account and
          making the appropriate changes. If you believe that the personal
          information we hold about you is incorrect, you are entitled to
          request amendment of such information. If we do not agree that there
          are grounds for amendment, then we will add a note to the personal
          information stating that you disagree with it.
          <br /> If you know the information we hold about you is not accurate
          or complete, please notify us. We will respond to requests for access
          within 30 days. We will retain your information for as long as your
          account is active or as needed to provide you services. We will retain
          and use your information as necessary to comply with our legal
          obligations, resolve disputes, and enforce our agreements.
          <br />
          <p>
            <strong className="orangeFont">7. PRIVACY CONCERNS</strong>
          </p>
          <br style={{ marginBottom: "50px" }} /> If you have any concerns about
          how we handle your personal information or require further
          information, please contact the Privacy Officer at e-mail
          info@warrantyme.co <br />
          <strong className="orangeFont"> 8. CONTACT US</strong>
          <br /> Customer enquiries: info@warrantyme.co Postal Address:
          2-10-1010, Jyothinagar, Karimnagar
        </p>
      </div>
      <div className="bottompage">
        <BottomBar />
      </div>
    </div>
  );
}
