import React from "react";
import "./MaterialBusinessCarousel.css";
import Carousel from "react-material-ui-carousel";
import businesscarousel1 from "./../../assets/businesscarousel1.png";
import businesscarousel2 from "./../../assets/businesscarousel2.png";
import businesscarousel3 from "./../../assets/businesscarousel3.png";
import businesscarousel4 from "./../../assets/businesscarousel4.png";
import { Avatar } from "@mui/material";

const item = [
  {
    id: 1,
    header: "Provide standardized after-sales service",
    descrption:
      "​We know that you love your customers and we love them too. Give them a reason to keep coming back to you. Use WarrantyMe to provide exceptional after-sales service to your customers. And the best part is, reduce your costs by doing so. Isn't this magical ? ",
    image: businesscarousel3,
  },
  {
    id: 2,
    header: "Exclusive Reward Program for you and your customers",
    descrption:
      "It's difficult to make loyal customers and we know that you have a lot of them. Now reward your customers with loyalty points and let them know that they are special. Your customers will redeem these loyalty points and shop more with you. Isn't that great :-)",
    image: businesscarousel1,
  },
  {
    id: 3,
    header: "Reconnect and Re-engage with your customers",
    descrption:
      "​We understand that sometimes you lose connection with your beloved customers. But not anymore, with WarrantyMe you can rebuild this connection. Use WarrantyMe to push exclusive offers for your customers and rebuild that lost connection. Afterall, what is more precious than this special bond between you and your customers",
    image: businesscarousel4,
  },
  {
    id: 4,
    header: "Sell Extended Warranty and Insurance ",
    descrption:
      "Get access to our range of Extended warranty and Insurance products by using or retailer app or just by integrating our API on your POS. it works like magic which open up an additional revenue source for your store.",
    image: businesscarousel2,
  },
];

export default function MaterialBusinessCarousel() {
  return (
    <div>
      <div
        style={{ minHeight: "700px", marginTop: "70px" }}
        className="carouselWeb"
      >
        <Carousel
          height="700px"
          indicatorIconButtonProps={{
            style: {
              backgroundColor: "white",
              color: "white",
              marginLeft: "12px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#FD6317",
              color: "#FD6317",
              marginLeft: "12px",
            },
          }}
          indicatorContainerProps={{
            style: {
              marginTop: "5px",
            },
          }}
          NextIcon={null}
          PrevIcon={null}
        >
          <div
            style={{
              minWidth: "763px",
              maxWidth: "763px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "calc(50vw - 381px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[0].image}
              sx={{ width: 172, height: 369 }}
              className="carouselImage"
            />
            <div className="packageType">{item[0].header}</div>
            <div className="packageAmount">{item[0].descrption}</div>
          </div>
          <div
            style={{
              minWidth: "763px",
              maxWidth: "763px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "calc(50vw - 381px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[1].image}
              sx={{ width: 172, height: 369 }}
              className="carouselImage"
            />
            <div className="packageType">{item[1].header}</div>
            <div className="packageAmount">{item[1].descrption}</div>
          </div>
          <div
            style={{
              minWidth: "763px",
              maxWidth: "763px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "calc(50vw - 381px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[2].image}
              sx={{ width: 172, height: 369 }}
              className="carouselImage"
            />
            <div className="packageType">{item[2].header}</div>
            <div className="packageAmount">{item[2].descrption}</div>
          </div>
          <div
            style={{
              minWidth: "763px",
              maxWidth: "763px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "calc(50vw - 381px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[3].image}
              sx={{ width: 172, height: 369 }}
              className="carouselImage"
            />
            <div className="packageType">{item[3].header}</div>
            <div className="packageAmount">{item[3].descrption}</div>
          </div>
        </Carousel>
      </div>
      <div
        style={{ minHeight: "750px", marginTop: "30px" }}
        className="carouselMobile"
      >
        <Carousel
          height="750px"
          indicatorIconButtonProps={{
            style: {
              backgroundColor: "white",
              color: "white",
              marginLeft: "8px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#FD6317",
              color: "#FD6317",
              marginLeft: "8px",
            },
          }}
          indicatorContainerProps={{
            style: {
              marginTop: "5px",
            },
          }}
          NextIcon={null}
          PrevIcon={null}
        >
          <div
            className="bottomContainer2Bus"
            style={{
              minWidth: "100vw",
              minHeight: "600px",
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "50px",
              // paddingBottom: "50px",
              // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Avatar
              variant="square"
              src={item[0].image}
              sx={{ width: 172, height: 369 }}
            />
            <div className="packageType">{item[0].header}</div>
            <div className="packageAmount">{item[0].descrption}</div>
          </div>
          <div
            className="bottomContainer2Bus"
            style={{
              minWidth: "100vw",
              minHeight: "600px",
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "50px",
              // paddingBottom: "50px",
              // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Avatar
              variant="square"
              src={item[1].image}
              sx={{ width: 172, height: 369 }}
            />
            <div className="packageType">{item[1].header}</div>
            <div className="packageAmount">{item[1].descrption}</div>
          </div>
          <div
            className="bottomContainer2Bus"
            style={{
              minWidth: "100vw",
              minHeight: "600px",
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "50px",
              // paddingBottom: "50px",
              // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Avatar
              variant="square"
              src={item[2].image}
              sx={{ width: 172, height: 369 }}
            />
            <div className="packageType">{item[2].header}</div>
            <div className="packageAmount">{item[2].descrption}</div>
          </div>
          <div
            className="bottomContainer2Bus"
            style={{
              minWidth: "100vw",
              minHeight: "600px",
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "50px",
              // paddingBottom: "50px",
              // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Avatar
              variant="square"
              src={item[3].image}
              sx={{ width: 172, height: 369 }}
            />
            <div className="packageType">{item[3].header}</div>
            <div className="packageAmount">{item[3].descrption}</div>
          </div>
        </Carousel>
      </div>
      <div
        style={{  minHeight: "1300px", marginTop: "140px"  }}
        className="carouselDesktop"
      >
        <Carousel
          height="1300px"
          indicatorIconButtonProps={{
            style: {
              backgroundColor: "white",
              color: "white",
              marginLeft: "12px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#FD6317",
              color: "#FD6317",
              marginLeft: "12px",
            },
          }}
          indicatorContainerProps={{
            style: {
              marginTop: "-185px",
            },
          }}
          NextIcon={null}
          PrevIcon={null}
        >
          <div
            style={{
              minWidth: "963px",
              maxWidth: "963px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "calc(50vw - 481px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[0].image}
              sx={{ width: 272, height: 560 }}
              className="carouselImage"
            />
            <div className="packageType">{item[0].header}</div>
            <div className="packageAmount">{item[0].descrption}</div>
          </div>
          <div
            style={{
              minWidth: "963px",
              maxWidth: "963px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "calc(50vw - 481px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[1].image}
              sx={{ width: 272, height: 560 }}
              className="carouselImage"
            />
            <div className="packageType">{item[1].header}</div>
            <div className="packageAmount">{item[1].descrption}</div>
          </div>
          <div
            style={{
              minWidth: "963px",
              maxWidth: "963px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "calc(50vw - 481px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[2].image}
              sx={{ width: 272, height: 560 }}
              className="carouselImage"
            />
            <div className="packageType">{item[2].header}</div>
            <div className="packageAmount">{item[2].descrption}</div>
          </div>
          <div
            style={{
              minWidth: "963px",
              maxWidth: "963px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "50px",
              paddingBottom: "50px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "calc(50vw - 481px)",
              borderWidth: "1px",
              borderRadius: "12px",
              borderColor: "rgba(101, 101, 101, 1)",
              backgroundColor: "#1F2228",
            }}
          >
            <Avatar
              variant="square"
              src={item[3].image}
              sx={{ width: 272, height: 560 }}
              className="carouselImage"
            />
            <div className="packageType">{item[3].header}</div>
            <div className="packageAmount">{item[3].descrption}</div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}
